import {
  Card,
  Col,
  Dropdown,
  Input,
  Menu,
  Row,
  Table,
  Tag,
  message,
  Button,
} from 'antd';
import { PrinterOutlined, MoreOutlined } from '@ant-design/icons';
import React, { useEffect, useState, Fragment } from 'react';
import Axios from 'axios';
import {url} from '../../../config/global'
import HistoryScan from './HistoryScan';
import LeaderCheck from './LeaderCheck';

function DetailInvoice({ id, rows, no_invoice, po_number }) {
    const [data, setData] = useState([]);
    const [total, setTotal] = useState([]);
    const [page, setPage] = useState(1);
    const [perpage, setPerpage] = useState(5);
    const [keyword, setKeyword] = useState('');
    const [sort, setSort] = useState({
        sort: 'created_at',
        order: 'descend',
    });
    const [loading, setLoading] = useState(false);
    const [expandedRowKeys, setExpandedRowKeys] = useState([]);
    const [modalLeader, setModalLeader] = useState(false);
    const [modalId, setModalId] = useState('');
    const [isLeaderDisabled, setIsLeaderDisabled] = useState(false);
    const [isDisabled, setIsDisabled] = useState(true);
    const [searchDisabled, setSearchDisabled] = useState(false);
    const [item, setItem] = useState({
      id: '',
      no_invoice: '',
      po_number: '',
      part_number: '',
      part_number_external: '',
      sourcing: '',
      description: '',
      total_qty: '',
      satuan: '',
      qty_actual_receipt: '',
      tgl_receipt: '',
      unique_code: '',
      label_part: '',
      label_kemasan: '',
      remarks: '',
  })
  const [error, setError] = useState({
    qty_actual_receipt: ''
  })
  const [buttonAccess, setButtonAccess] = useState(null);


    const handleTableChange = (pagination, filters, sorter) => {
        setPage(pagination.current);
        setPerpage(pagination.pageSize);
        setSort({
            sort: sorter.field,
            order: sorter.order,
        });
    };

    useEffect(() => {
        getDetails();
        const pathname = window.location.pathname.split('/');
        console.log(pathname);
        const currentUrl = pathname[1];
        const permissions =
            sessionStorage.getItem('permissions') &&
            JSON.parse(sessionStorage.getItem('permissions'));
        
        if (permissions) {
            const findPermission = permissions.find(
                (permission) => permission.url === currentUrl,
            );

            const findChildPermission = permissions
                .map((child) => {
                    return child.children.find(
                        (grandChild) => grandChild.url === currentUrl,
                    );
                })
                .filter((grandChild) => grandChild)[0];

            if (findChildPermission && findChildPermission.children) {
                if (findChildPermission.children) {
                    const findButtonAccessChildren =
                        findChildPermission.children
                            .filter((x) => x !== null)
                            .filter((x) => x.type === 'Button');
                    if (findButtonAccessChildren) {
                        setButtonAccess(
                            findButtonAccessChildren.filter((x) => x !== null),
                        );
                    }
                }
            }

            if (findPermission && findPermission.children) {
                const findButtonAccess = findPermission.children.filter(
                    (x) => x.type === 'Button',
                );
                if (findButtonAccess) {
                    setButtonAccess(findButtonAccess);
                }
            }
        }
        return () => {
            setData([]);
        };
    }, [page, perpage, sort, keyword, no_invoice, po_number]);

    const getDetails = () => {
        setLoading(true);
        Axios.get(`${url}/invoice-get-detail`, {
            params: {
                keyword,
                no_invoice:no_invoice,
                po_number:po_number,
                perpage: perpage,
                page,
                sort: sort.sort,
                order: sort.order,
                columns: ['part_number', 'description'],
            },
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem('token')}`
            }
        })
            .then((res) => {
                setData(res.data.data);
                setTotal(res.data.total);
            })
            .catch((error) => {
                if (error.response) {
                    message.error(error.response.data.message);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const handlePrint = (id) => {
        setLoading(true);
        Axios.get(`/generate-label/history/${id}/print`, {
            responseType: 'blob',
        })
            .then(({ data }) => {
                const file = new Blob([data], { type: 'application/pdf' });
                const fileURL = URL.createObjectURL(file);
                window.open(fileURL);

                getDetails();
            })
            .catch((err) => {
              if (err.response) {
                message.error(err.response.data.message)
                  if (err.response.status === 422) {
                      message.error(err.response.data.errors)
                  }
                  if (err.response.status === 401) {
                      sessionStorage.removeItem('token')
                  }
              } else {
                  message.error('Backend not yet Started')
              }
            })
            .finally(() => setLoading(false));
    };

    const handleReprintAll = async (record) => {
      setLoading(true);
      try {
          const { data } = await Axios.get(`${url}/warehouse-checksheets/reprint-all`, {
              params: {
                part_number : record.part_number,
                no_invoice : record.number,
                po_number : record.po_number,
              },
              headers: {
                  Authorization: `Bearer ${sessionStorage.getItem('token')}`,
              },
              responseType: 'blob', // Move responseType here
          });
  
          // Check if data exists
          if (data) {
              const file = new Blob([data], { type: 'application/pdf' });
              const fileURL = URL.createObjectURL(file);
              window.open(fileURL);
          } else {
              message.error('No data returned from server.');
          }
      } catch (err) {
          if (err.response) {
            message.error(err.response.data.message)
            if (err.response.status === 422) {
                message.error(err.response.data.errors)
            }
            if (err.response.status === 401) {
                sessionStorage.removeItem('token')
            }
        } else {
            message.error('Backend not yet Started')
        }
      } finally {
          setLoading(false);
          getDetails();
      }
    };

    const handleExpand = (expanded, record) => {
        const newExpandedRowKeys = expanded ? [record._id] : [];
        setExpandedRowKeys(newExpandedRowKeys);
    };

    const showLeaderModal = (id) => {
      console.log(modalLeader, "leader modal open");
      setModalId(id)
      setError(null);
      setModalLeader(true);
      setIsLeaderDisabled(true);
      setIsDisabled(true);
      setSearchDisabled(true);
    };



  // Fungsi submit modal koreksi user leader
  const handleOkLeaderModal = async () => {
    if(item.qty_actual_receipt === ''){
      message.error("Qty Actual Receipt is required")
    }else {
      setModalId('');
      setModalLeader(false);
      setError(null)
      setLoading(true);
      setIsDisabled(true);
      setSearchDisabled(true);
      setIsLeaderDisabled(true);
      await Axios.post(`${url}/warehouse-checksheets/submit-leader-invoice/${item.id}`,{
        no_invoice: item.no_invoice,
        po_number: item.po_number,
        qty: item.qty_actual_receipt,
        unique_code: item.unique_code,
        remarks: item.remarks,
        label_part: item.label_part,
        label_kemasan: item.label_kemasan,
      },{
        headers: {
            Authorization: `Bearer ${sessionStorage.getItem('token')}`
        }
      }).then(res => {
          message.success(res.data.message)
          setItem({
            ...item,
            id: '',
            no_invoice: '',
            po_number: '',
            part_number: '',
            part_number_external: '',
            description: '',
            total_qty: '',
            balance: '',
            satuan: '',
            sourcing: '',
            qty_actual_receipt: '',
            tgl_receipt: '',
            unique_code: '',
            label_part : '',
            label_kemasan: '',
          });
      }).catch(err => {
        console.log(err.response)
        if (err.response) {
            message.error(err.response.data.message)
            if (err.response.status === 422) {
                setError(err.response.data.errors)
            }
            if (err.response.status === 401) {
                sessionStorage.removeItem('token')
            }
        } else {
            message.error('Backend not yet Started')
        }
      }).finally(() => {
        setLoading(false);
        setIsDisabled(false);
        setIsLeaderDisabled(false);
        setSearchDisabled(false);
        getDetails();
      })
    }
  };

  // Fungsi untuk menutup modal leader
  const handleCancelLeaderModal = () => {
    setModalId('')
    setModalLeader(false);
    setIsLeaderDisabled(false);
    setIsDisabled(false);
    setSearchDisabled(false);
    setItem({
      ...item,
      id: '',
      no_invoice: '',
      po_number: '',
      part_number: '',
      part_number_external: '',
      description: '',
      total_qty: '',
      balance: '',
      satuan: '',
      sourcing: '',
      qty_actual_receipt: '',
      tgl_receipt: '',
      unique_code: '',
      label_part : '',
      label_kemasan: '',
    });
  };

    const pagination = {
        pageSize: 5,
        current: page,
        total: total,
        showSizeChanger: false,
        onChange: (page) => setPage(page),
      };

    const formattedNumber = (value) => {
        return value.toLocaleString('id-ID')
    };

    const check_permission = (permission_button, name) => {
        let permission = permission_button
        ? permission_button.find(permission_upload_invoice => 
            permission_upload_invoice !== null && permission_upload_invoice.name 
            == name
            && permission_upload_invoice.allow == "true")
        : false;

        return permission;
    }


    let permission_invoice_leader_check = check_permission(buttonAccess, "Invoice Leader Check")

    let permission_invoice_reprint = check_permission(buttonAccess, "Invoice Reprint (Single & All)" );

    if(permission_invoice_leader_check && permission_invoice_leader_check.allow === "true"){
      permission_invoice_leader_check = true;
    }else {
        permission_invoice_leader_check = false;
    }

    if(permission_invoice_reprint && permission_invoice_reprint.allow === "true"){
        permission_invoice_reprint = true;
    }else {
        permission_invoice_reprint = false;
    }

    const columns = [
        // {
        //     title: 'NO',
        //     dataIndex: 'no',
        //     key: 'no',
        //     align: 'center',
        //     width:100,
        //     fixed: 'left',
        //     render: (text, record, index) => {
        //         return (pagination.current - 1) * pagination.pageSize + index + 1;
        //     }
        // },
        {
          title: 'PO NUMBER',
          dataIndex: 'po_number',
          key: 'po_number',
          align:'center',
          width:200,
        },
        {
          title: 'PART NUMBER',
          dataIndex: 'part_number',
          key: 'part_number',
          align:'center',
          width:200,
        },
        {
          title: 'DESCRIPTION',
          dataIndex: 'description',
          key: 'description',
          align:'center',
        },
        {
          title: 'SNP',
          align:'center',
          width:50,
          // dataIndex: 'snp',
          // key: 'snp',
          render: (text,record,index) => {
            return (formattedNumber(record.snp));
          }
        },
        {
          title: 'PACKING LIST',
          align:'center',
          children: [
            {
              title: 'PACKAGES',
              align: 'center',
              children:[
                {
                  dataIndex: 'packages',
                  key: 'packages',
                  align:'center',
                  width:50,
                },
                {
                  dataIndex: 'uom',
                  key: 'uom',
                  align:'center',
                  width:50,
                },
              ]
            },
            {
              title:'QUANTITY',
              align:'center',
              width:50,
              // dataIndex:'quantity',
              // key:'quantity',
              render: (text,record,index) => {
                return (formattedNumber(record.quantity));
              }
            },
            {
              title:'SATUAN',
              dataIndex:'uom',
              key:'uom',
              align:'center',
              width:50,
            },
          ],
        },
        {
          title: 'ACTUAL',
          align:'center',
          children: [
            {
              title:'QUANTITY',
              align:'center',
              width:50,
              // dataIndex:'qty_actual',
              // key:'qty_actual',
              render: (text,record,index) => {
                return (formattedNumber(record.qty_actual));
              }
            },
            {
              title:'TOTAL PACKAGES',
              dataIndex:'count_package',
              key:'count_package',
              align:'center',
              width:50,
            },
            {
              title:'SATUAN',
              dataIndex:'uom',
              key:'uom',
              align:'center',
              width:50,
            },
            {
              title:'BALANCES',
              align:'center',
              width:50,
              // dataIndex:'balance',
              // key:'balance',
              render: (text,record,index) => {
                return (formattedNumber(record.balance));
              }
            },
          ],
        },
        {
          title : 'CHECK ITEM',
          align:'center',
          children: [
            {
              title: 'LABEL PART',
              align: 'center',
              // dataIndex: 'label_part',
              // key:'label_part',
              render: (text,record,index) => {
                return `${record.label_part}%`;
              }
            },
            {
              title: 'KEMASAN',
              align: 'center',
              // dataIndex: 'kemasan',
              // key:'kemasan',
              render: (text,record,index) => {
                return `${record.kemasan}%`;
              }
            },
          ]
        },
        {
          title : 'PIC',
          align : 'center',
          children : [
            {
              title : 'UPLOAD BY',
              align : 'center',
              dataIndex:'created_by',
              key:'created_by',
            }
          ]
        },
        // {
        //   title : 'LEADER',
        //   align : 'center',
        //   dataIndex:'approved_by',
        //   key:'approved_by',
        //   render: (text,record,index) => {
        //     return record.approved_by ?? '-';
        //   }
        // },
        {
          title : 'ACTION',
          align : 'center',
          render: (text,record,index) => {
            if(permission_invoice_leader_check || permission_invoice_reprint){
              return (
                // <Fragment>
                //   <Dropdown className="pointer" overlay={
                //       <Menu>
                //           <Menu.Item key="1" onClick={() => handleReprintAll(record)}  disabled={record.count_receiving == 0}>
                //             Reprint All
                //           </Menu.Item>
                //           <Menu.Item key="2">
                //           <Button onClick={showLeaderModal}  disabled={record.count_receiving == 0}>Leader Check</Button>
                //             <LeaderCheck record={record} visible={modalLeader} onOk={handleOkLeaderModal} onCancel={handleCancelLeaderModal} item={item}
                //             setItem={setItem} />
                //         </Menu.Item>
                //       </Menu>
                //   } trigger={['click']}>
                //       <span className="pointer" onClick={e => e.preventDefault()}>
                //           <MoreOutlined />
                //       </span>
                //   </Dropdown>
                // </Fragment>
                <Fragment>
                  <Dropdown className="pointer" overlay={
                      <Menu>
                          {permission_invoice_leader_check && (
                            <Menu.Item key="1" onClick={() => handleReprintAll(record)}  disabled={record.count_receiving == 0}>
                              Reprint All
                            </Menu.Item>
                          )}
                          {permission_invoice_reprint && (
                            <Menu.Item key="2">
                                <Button type="text"
                                style={{
                                  border: 'none',
                                  textAlign: 'left', 
                                  padding: 0, // optional, to remove padding if needed
                                }}disabled={record.balance != 0} onClick={() => showLeaderModal(record._id)}>Leader Check</Button>
                                {
                                  modalLeader && modalId === record._id && (
                                    <LeaderCheck record={record} visible={modalLeader} onOk={handleOkLeaderModal} onCancel={handleCancelLeaderModal} disabled={isLeaderDisabled} item={item}
                                    setItem={setItem} />
                                  )
                                }
                              </Menu.Item>
                          )}
                      </Menu>
                  } trigger={['click']}>
                      <span className="pointer" onClick={e => e.preventDefault()}>
                          <MoreOutlined />
                      </span>
                  </Dropdown>
                </Fragment>
              );
            }else {
              return ('')
            }
          }
        },
      ];
    return (
        <Card>
            <Row justify="end">
                <Col>
                    <Input.Search
                        allowClear="true"
                        onSearch={(value) => setKeyword(value)}
                        placeholder="Search..."
                    />
                </Col>
            </Row>
            <Row style={{marginTop:'50px'}}>
                <Col xs={24}>
                    <Table
                        columns={columns}
                        dataSource={data}
                        pagination={pagination}
                        loading={loading}
                        rowKey={(data) => data._id}
                        onChange={handleTableChange}
                        scroll={{ x: true }}
                        expandable={{
                          expandedRowKeys: expandedRowKeys,
                          onExpand: handleExpand,
                          expandedRowRender: (record) => (
                              <HistoryScan
                                  key={`history-scan-${record._id}`}
                                  id={record._id}
                                  rows={expandedRowKeys}
                                  part_number={record.part_number}
                                  no_invoice={record.number}
                                  po_number={record.po_number}
                              />
                          ),
                      }}
                    />
                </Col>
            </Row>
        </Card>
    );
}

export default DetailInvoice;
