import {
  Card,
  Col,
  Dropdown,
  Input,
  Menu,
  Row,
  Table,
  Tag,
  message,
  Button,
} from 'antd';
import { PrinterOutlined, MoreOutlined } from '@ant-design/icons';
import React, { useEffect, useState, Fragment } from 'react';
import Axios from 'axios';
import {url} from '../../../config/global'
import moment from 'moment';

function DetailStockOpname({ id, rows, part_number }) {
    const [data, setData] = useState([]);
    const [total, setTotal] = useState([]);
    const [page, setPage] = useState(1);
    const [perpage, setPerpage] = useState(5);
    const [keyword, setKeyword] = useState('');
    const [sort, setSort] = useState({
        sort: 'created_at',
        order: 'descend',
    });
    const [loading, setLoading] = useState(false);
    const [expandedRowKeys, setExpandedRowKeys] = useState([]);
    const [modalLeader, setModalLeader] = useState(false);
    const [isLeaderDisabled, setIsLeaderDisabled] = useState(false);
    const [isDisabled, setIsDisabled] = useState(true);
    const [searchDisabled, setSearchDisabled] = useState(false);
    const [item, setItem] = useState({
      id: '',
      no_invoice: '',
      po_number: '',
      part_number: '',
      part_number_external: '',
      sourcing: '',
      description: '',
      total_qty: '',
      satuan: '',
      qty_actual_receipt: '',
      tgl_receipt: '',
      unique_code: '',
      label_part: '',
      label_kemasan: '',
      remarks: '',
  })
  const [error, setError] = useState({
    qty_actual_receipt: ''
  })


    const handleTableChange = (pagination, filters, sorter) => {
        setPage(pagination.current);
        setPerpage(pagination.pageSize);
        setSort({
            sort: sorter.field,
            order: sorter.order,
        });
    };

    useEffect(() => {
        getDetails();
        return () => {
            setData([]);
        };
    }, [page, perpage, sort, keyword, part_number]);

    const getDetails = () => {
        setLoading(true);
        Axios.get(`${url}/warehouse-sto-get-detail`, {
            params: {
                keyword,
                id:id,
                part_no_erp:part_number,
                perpage: perpage,
                page,
                sort: sort.sort,
                order: sort.order,
                columns: ['part_no_erp', 'description'],
            },
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem('token')}`
            }
        })
            .then((res) => {
                setData(res.data.data);
                setTotal(res.data.total);
            })
            .catch((error) => {
                if (error.response) {
                    message.error(error.response.data.message);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const handlePrint = (id) => {
        setLoading(true);
        Axios.get(`/generate-label/history/${id}/print`, {
            responseType: 'blob',
        })
            .then(({ data }) => {
                const file = new Blob([data], { type: 'application/pdf' });
                const fileURL = URL.createObjectURL(file);
                window.open(fileURL);

                getDetails();
            })
            .catch((error) => {
                if (error.response.status === 500) {
                    message.error('Server error');
                } else {
                    if (error.response) {
                        const reader = new FileReader();
                        reader.onload = function () {
                            const errorMessage = JSON.parse(reader.result);
                            message.error(errorMessage.message);
                        };
                        reader.readAsText(error.response.data);
                    } else {
                        console.error('Error:', error.message.message);
                    }
                }
            })
            .finally(() => setLoading(false));
    };

    const handleReprintAll = async (record) => {
      setLoading(true);
      try {
          const { data } = await Axios.get(`${url}/warehouse-checksheets/reprint-all`, {
              params: {
                part_number : record.part_number,
              },
              headers: {
                  Authorization: `Bearer ${sessionStorage.getItem('token')}`,
              },
              responseType: 'blob', // Move responseType here
          });
  
          // Check if data exists
          if (data) {
              const file = new Blob([data], { type: 'application/pdf' });
              const fileURL = URL.createObjectURL(file);
              window.open(fileURL);
          } else {
              message.error('No data returned from server.');
          }
      } catch (error) {
          if (error.response) {
              if (error.response.status === 500) {
                  message.error('Server error');
              } else {
                  const reader = new FileReader();
                  reader.onload = function () {
                      try {
                          const errorMessage = JSON.parse(reader.result);
                          message.error(errorMessage.message || 'Error processing the request.');
                      } catch (err) {
                          message.error('Error reading the error message.');
                      }
                  };
                  reader.readAsText(error.response.data);
              }
          } else {
              message.error(`Error: ${error.message}`);
          }
      } finally {
          setLoading(false);
          getDetails();
      }
    };

    const handleReprint = async (record) => {
        setLoading(true);
        try {
            const { data } = await Axios.get(`${url}/warehouse-sto/reprint`, {
                params: {
                  id : record._id,
                },
                headers: {
                    Authorization: `Bearer ${sessionStorage.getItem('token')}`,
                },
                responseType: 'blob', // Move responseType here
            });
    
            // Check if data exists
            if (data) {
                const file = new Blob([data], { type: 'application/pdf' });
                const fileURL = URL.createObjectURL(file);
                window.open(fileURL);
            } else {
                message.error('No data returned from server.');
            }
        } catch (error) {
            if (error.response) {
                if (error.response.status === 500) {
                    message.error('Server error');
                } else {
                    const reader = new FileReader();
                    reader.onload = function () {
                        try {
                            const errorMessage = JSON.parse(reader.result);
                            message.error(errorMessage.message || 'Error processing the request.');
                        } catch (err) {
                            message.error('Error reading the error message.');
                        }
                    };
                    reader.readAsText(error.response.data);
                }
            } else {
                message.error(`Error: ${error.message}`);
            }
        } finally {
            setLoading(false);
            getDetails();
        }
      };

    const handleExpand = (expanded, record) => {
        const newExpandedRowKeys = expanded ? [record._id] : [];
        setExpandedRowKeys(newExpandedRowKeys);
    };

    const showLeaderModal = () => {
      console.log(modalLeader, "leader modal open");
      setError(null);
      setModalLeader(true);
      setIsLeaderDisabled(true);
      setIsDisabled(true);
      setSearchDisabled(true);
    };



  // Fungsi submit modal koreksi user leader
  const handleOkLeaderModal = async () => {
    if(item.qty_actual_receipt === ''){
      message.error("Qty Actual Receipt is required")
    }else {
      setModalLeader(false);
      setError(null)
      setLoading(true);
      setIsDisabled(true);
      setSearchDisabled(true);
      setIsLeaderDisabled(true);
      await Axios.post(`${url}/warehouse-checksheets/submit-leader-invoice/${item.id}`,{
        no_invoice: item.no_invoice,
        po_number: item.po_number,
        qty: item.qty_actual_receipt,
        unique_code: item.unique_code,
        remarks: item.remarks,
        label_part: item.label_part,
        label_kemasan: item.label_kemasan,
      },{
        headers: {
            Authorization: `Bearer ${sessionStorage.getItem('token')}`
        }
      }).then(res => {
          message.success(res.data.message)
          setItem({
            ...item,
            id: '',
            no_invoice: '',
            po_number: '',
            part_number: '',
            part_number_external: '',
            description: '',
            total_qty: '',
            balance: '',
            satuan: '',
            sourcing: '',
            qty_actual_receipt: '',
            tgl_receipt: '',
            unique_code: '',
            label_part : '',
            label_kemasan: '',
          });
      }).catch(err => {
        console.log(err.response)
        if (err.response) {
            message.error(err.response.data.message)
            if (err.response.status === 422) {
                setError(err.response.data.errors)
            }
            if (err.response.status === 401) {
                sessionStorage.removeItem('token')
            }
        } else {
            message.error('Backend not yet Started')
        }
      }).finally(() => {
        setLoading(false);
        setIsDisabled(false);
        setIsLeaderDisabled(false);
        setSearchDisabled(false);
        getDetails();
      })
    }
  };

  // Fungsi untuk menutup modal leader
  const handleCancelLeaderModal = () => {
    setModalLeader(false);
    setIsLeaderDisabled(false);
    setIsDisabled(false);
    setSearchDisabled(false);
    setItem({
      ...item,
      id: '',
      no_invoice: '',
      po_number: '',
      part_number: '',
      part_number_external: '',
      description: '',
      total_qty: '',
      balance: '',
      satuan: '',
      sourcing: '',
      qty_actual_receipt: '',
      tgl_receipt: '',
      unique_code: '',
      label_part : '',
      label_kemasan: '',
    });
  };

    const pagination = {
        pageSize: 5,
        current: page,
        total: total,
        showSizeChanger: false,
        onChange: (page) => setPage(page),
      };

    const formattedNumber = (value) => {
        return value.toLocaleString('id-ID')
    };

    const formattedPartStatus = (value) => {
        if(value == 'ok'){
            return 'OK';
        }else if (value == 'ng'){
            return 'NG';
        }else if (value == 'rework') {
            return 'REWORK';
        }
    };

    const formattedJenisMaterial = (value) => {
        if(value == 'material'){
            return 'Material';
        }else if (value == 'in_process'){
            return 'In Process';
        }else if (value == 'finish_process') {
            return 'Finish Process';
        }
    };

    const columns = [
        {
            title: 'NO',
            dataIndex: 'no',
            key: 'no',
            align: 'center',
            width:100,
            fixed: 'left',
            render: (text, record, index) => {
                return (pagination.current - 1) * pagination.pageSize + index + 1;
            }
        },
        {
          title : 'QR CODE',
          align : 'center',
          dataIndex : 'qr_code',
          key : 'qr_code',
        },
        {
            title: 'GENERATE NUMBER',
            align:'center',
            dataIndex: 'gen_number',
            key: 'gen_number',
        },
        {
            title: 'SEQUENCE',
            align:'center',
            dataIndex: 'sequence',
            key: 'sequence',
        },
        {
            title: 'PART STATUS',
            align:'center',
            dataIndex: 'part_status',
            key: 'part_status',
            render: (text, record, index) =>{
                return formattedPartStatus(record.part_status ?? 0);
            }
        },
        {
            title: 'JENIS MATERIAL',
            align:'center',
            dataIndex: 'jenis_material',
            key: 'jenis_material',
            render: (text, record, index) =>{
                return formattedJenisMaterial(record.jenis_material ?? 0);
            }
        },
        {
          title : 'QTY',
          align : 'center',
          dataIndex : 'qty',
          key : 'qty',
          render: (text, record, index) =>{
            return formattedNumber(record.qty ?? 0);
          }
        },
        {
          title : 'STO BY',
          align : 'center',
          dataIndex:'sto_by',
          key:'sto_by',
        },
        {
          title : 'LEADER',
          align : 'center',
          dataIndex:'leader',
          key:'leader',
        },
        {
          title : 'STATUS',
          align : 'center',
          dataIndex:'status',
          key:'status',
        },
        {
          title : 'AREA',
          align : 'center',
          dataIndex:'area_name',
          key:'area_name',
        },
        {
            title: 'CREATED AT',
            align:'center',
            dataIndex: 'created_at',
            key: 'created_at',
            width:200,
            render : (text,record,index) => {
                return moment(record.created_at).format('YYYY-MM-DD HH:mm:ss');
            }
        },
        {
          title : 'ACTION',
          align : 'center',
          render: (text,record,index) => {
              return (
                <Fragment>
                  <Dropdown className="pointer" overlay={
                      <Menu>
                        <Menu.Item key="1" onClick={() => handleReprint(record)} disabled={record.status !== 'APPROVED'}>
                              Reprint
                        </Menu.Item>
                      </Menu>
                  } trigger={['click']}>
                      <span className="pointer" onClick={e => e.preventDefault()}>
                          <MoreOutlined />
                      </span>
                  </Dropdown>
                </Fragment>
              );
          }
        },
      ];
    return (
        <Card>
            <Row justify="end">
                <Col>
                    <Input.Search
                        allowClear="true"
                        onSearch={(value) => setKeyword(value)}
                        placeholder="Search..."
                    />
                </Col>
            </Row>
            <Row style={{marginTop:'50px'}}>
                <Col xs={24}>
                    <Table
                        columns={columns}
                        dataSource={data}
                        pagination={pagination}
                        loading={loading}
                        rowKey={(data) => data._id}
                        onChange={handleTableChange}
                        scroll={{ x: true }}
                    />
                </Col>
            </Row>
        </Card>
    );
}

export default DetailStockOpname;
