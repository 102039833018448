import React, {useState, useEffect} from 'react'
import {Tooltip, message} from 'antd';
import { url } from "../../../config/global";
import Axios from 'axios';

const ReceiveTooltip = ({record, receiving}) => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    return (
        <div>
            <Tooltip 
                title={
                <div>
                    <table>
                        <tbody>
                        <tr>
                            <td style={{fontSize: 18}}>No Invoice</td>
                            <td style={{fontSize: 18}}>:</td>
                            <td style={{fontSize: 18}}>{record.length > 0 ? (record[0]?.no_invoice ?? '-') : '-'}</td>
                        </tr>
                        <tr>
                            <td style={{fontSize: 18}}>Incoming By</td>
                            <td style={{fontSize: 18}}>:</td>
                            <td style={{fontSize: 18}}>{record.length > 0 ? (record[0]?.created_by ?? '-') : '-'}</td>
                        </tr>
                        <tr>
                            <td style={{fontSize: 18}}>Leader</td>
                            <td style={{fontSize: 18}}>:</td>
                            <td style={{fontSize: 18}}>{record.length > 0 ? (record[0]?.approved_by ?? '-') : '-'}</td>
                        </tr>
                        <tr>
                            <td style={{fontSize: 18}}>Total Qty</td>
                            <td style={{fontSize: 18}}>:</td>
                            <td style={{fontSize: 18}}>{record.length > 0 ? (record[0]?.total_qty ?? '-') : '-'}</td>
                        </tr>
                        <tr>
                            <td style={{fontSize: 18}}>Sourcing</td>
                            <td style={{fontSize: 18}}>:</td>
                            <td style={{fontSize: 18}}>{record.length > 0 ? (record[0]?.sourcing?? '-') : '-'}</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                }
                color={'#6e6d6d'}
                trigger={['click']}
            >
                <span>{receiving}</span>
            </Tooltip>
        </div>
    )
}

export default ReceiveTooltip