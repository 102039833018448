import React, { Fragment, useState } from 'react'
import { Modal, Form, Button, Select, Layout, Row, Col, Card, Breadcrumb, Typography, Upload, List, message } from 'antd';
import { Link, Redirect } from 'react-router-dom';
import { DeleteOutlined, FileExcelOutlined } from '@ant-design/icons';
import Axios from 'axios';
import { url } from '../../config/global';

const { Title } = Typography
const { Option } = Select;
const { Content } = Layout

const layout = {
    labelCol: {
        lg: 8,
        md: 10
    },
    wrapperCol: {
        lg: 8,
        md: 14
    },
}
const tailLayout = {
    wrapperCol: {
        offset: 8,
        span: 16,
    },
}

function UploadInvoice(props) {

    const [data, setData] = useState({
        file: null
    })

    const [visible, setVisible] = useState(false);
    const [messages, setMessages] = useState('');
    const [titleModal, setTitleModal] = useState('');

    const [error, setError] = useState(null)
    const [loading, setLoading] = useState(false)

    const handleRemove = () => {
        setData({
            ...data,
            file: null
        })
    }

    const handleBeforeUpload = (files) => {

        setData({
            ...data,
            file: files
        })

        return false
    }

    const onSaveData = () => {
        setTitleModal('');
        setLoading(true)
        setVisible(false)
        setMessages('');

        let fd = new FormData()

        fd.append('file', data.file)

        Axios.post(`${url}/invoice-import`, fd, {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${sessionStorage.getItem('token')}`
            }
        }).then(res => {
            message.success(res.data.message)
            props.history.push('/invoice')
        }).catch(err => {
            if (err.response) {
                if(err.response.data.item === 'not found'){
                    setTitleModal('List Item Not Found On Item Group')
                    // Combine the messages into one string and split them into lines with <br />
                    const combinedMessage = err.response.data.message.map((item, index) => (
                        <span key={index}>{index + 1}. {item}<br /></span>
                    ));
    
                    // Display the combined message
                    setMessages(combinedMessage);
                }else if (err.response.data.item == 'incomplete'){
                    setTitleModal('List Item Incomplete On Item Group')
                    // Combine the messages into one string and split them into lines with <br />
                    const combinedMessage = err.response.data.message.map((item, index) => (
                        <span key={index}>{index + 1}. {item}<br /></span>
                    ));
    
                    // Display the combined message
                    setMessages(combinedMessage);
                }else {
                    setError(err.response.data.errors)
                }
                setVisible(true);
                if (err.response.status === 422) {
                    setError(err.response.data.errors)
                }
                if (err.response.status === 401) {
                    sessionStorage.removeItem('token')
                }
            } else {
                message.error('Backend not yet Started')
            }
            setLoading(false)
        })
    }

    const handleReset = () => {
        setData({
            file: null,
        })
    }

    const onOk = () => {
        setVisible(false);
    }

    const onCancel = () => {
        setVisible(false);
    }

    if (!sessionStorage.getItem('token')) {
        return <Redirect to="/login" />
    }

    return (
        <Fragment>
            <Row>
                <Col xs={24}>
                    <Card>
                        <Title level={4}>Upload Invoice</Title>
                        <Row type="flex" justify="left">
                            <Breadcrumb>
                                <Breadcrumb.Item>
                                    <Link to="/invoice">Invoice</Link>
                                </Breadcrumb.Item>
                                <Breadcrumb.Item>Upload Invoice</Breadcrumb.Item>
                            </Breadcrumb>
                        </Row>
                    </Card>
                </Col>
            </Row>
            <Content className="content">
                <Row>
                    <Col xs={24}>
                        <Card className="body-data">
                            <Form 
                            {...layout}
                            name="control-hooks"
                            onFinish={onSaveData}
                            >
                                <Form.Item
                                    name="file"
                                    label="File"
                                >
                                <Fragment>
                                    <Upload onRemove={handleRemove} beforeUpload={handleBeforeUpload} fileList={[]}>
                                        <Button>
                                            Choose File <FileExcelOutlined /> 
                                        </Button>
                                    </Upload>
                                    {
                                        data.file && <List>
                                            <List.Item actions={[<Button onClick={handleRemove} type="danger" ghost><DeleteOutlined /></Button>]}>
                                                <List.Item.Meta description={data.file.name} />
                                            </List.Item>
                                        </List>
                                    }
                                </Fragment>
                                </Form.Item>
                                <Form.Item {...tailLayout}>
                                    <Button loading={loading} type="primary" htmlType="submit" className="mr-button">
                                        Upload
                                    </Button>
                                    <Button htmlType="reset" onClick={handleReset}>
                                        Reset
                                    </Button>
                                </Form.Item>  
                            </Form>
                        </Card>
                    </Col>
                </Row>
                <Modal
                    title={titleModal}
                    visible={visible}
                    onOk={onOk}
                    onCancel={onCancel}
                    width={500}  
                    closable={false}   // Hides the close icon (X)
                    footer={[
                        <Button key="ok" type="primary" onClick={onOk}>
                            OK
                        </Button>,
                    ]} 
                > 
                <div style={{ maxHeight: '200px', overflowY: 'auto', overflowX: 'auto' }}>
                    <>{messages}</>
                </div>
                </Modal>
            </Content>
        </Fragment>
    )
}

export default UploadInvoice