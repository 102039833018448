import React, { Fragment, useState, useEffect } from 'react'
import { Row, Col, Card, Typography, Breadcrumb, Layout, Button, message, Table, Input, Space, Dropdown, Menu, Modal } from 'antd'
import {Link,Redirect} from 'react-router-dom';
import Axios from 'axios';
import {url} from '../../config/global'
import { UploadOutlined, CaretDownOutlined, ReloadOutlined, MoreOutlined, DeleteOutlined} from '@ant-design/icons'
import moment from 'moment'
import fileDownload from 'js-file-download'
import DetailInvoice from './components/DetailInvoice';

const { Title } = Typography
const { Search } = Input
const { Content } = Layout

function Invoice() {

    const [keyword, setKeyword] = useState('')
    const [invoices, setInvoices] = useState([])
    const [loading, setLoading] = useState(false)
    const [page, setPage] = useState(1)
    const [sort, setSort] = useState({
        sort: 'created_at',
        order: 'descend'
    })
    const [expandedRowKeys, setExpandedRowKeys] = useState([]);
    const [selectedRows, setSelectedRows] = useState([]);
    const [selectedData, setSelectedData] = useState([]);
    const [buttonAccess, setButtonAccess] = useState(null);

    const pagination = {
        pageSize: 10,
        current: page,
        total: invoices.total,
        showSizeChanger: false,
        onChange: (page) => setPage(page),
      };


    const handleSearch = (value) => {
        setKeyword(value)
        sessionStorage.setItem('keyword', value)
        setPage(1)

        if (value === '') {
            getData()
        }
    }

    useEffect(() => {
        getData()
        const pathname = window.location.pathname.split('/');
        console.log(pathname);
        const currentUrl = pathname[1];
        const permissions =
            sessionStorage.getItem('permissions') &&
            JSON.parse(sessionStorage.getItem('permissions'));
        
        if (permissions) {
            const findPermission = permissions.find(
                (permission) => permission.url === currentUrl,
            );

            const findChildPermission = permissions
                .map((child) => {
                    return child.children.find(
                        (grandChild) => grandChild.url === currentUrl,
                    );
                })
                .filter((grandChild) => grandChild)[0];

            if (findChildPermission && findChildPermission.children) {
                if (findChildPermission.children) {
                    const findButtonAccessChildren =
                        findChildPermission.children
                            .filter((x) => x !== null)
                            .filter((x) => x.type === 'Button');
                    if (findButtonAccessChildren) {
                        setButtonAccess(
                            findButtonAccessChildren.filter((x) => x !== null),
                        );
                    }
                }
            }

            if (findPermission && findPermission.children) {
                const findButtonAccess = findPermission.children.filter(
                    (x) => x.type === 'Button',
                );
                if (findButtonAccess) {
                    setButtonAccess(findButtonAccess);
                }
            }
        }
    }, [keyword, page, sort])

    const getData = () => {
        setLoading(true)
        Axios.get(`${url}/invoice`, {
            params: {
                keyword,
                columns: [
                    'number',
                    'po_number',
                    'part_number'
                ],
                perpage: 10,
                page,
                sort: sort.sort,
                order: sort.order
            },
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem('token')}`
            }
        }).then(res => {
            setInvoices(res.data)
        }).catch(err => {
            if (err.response) {  
                message.error(err.response.data.message)
            } else {
                message.error('Backend not yet Started')
            }
        }).finally(() => {
            setLoading(false)
        })
    }

    const handleTableChange = (pagination, filters, sorter) => {
        setPage(pagination.current)
        setSort({
            sort: sorter.field,
            order: sorter.order
        })
    }

    const downloadTemplate = () => {
        Axios.get(`${url}/invoice-download`, {
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem('token')}`
            },
            responseType: 'blob'
        }).then(res => {
            fileDownload(res.data, 'template_invoice.xlsx')
        })
    }

    const handleExpand = (expanded, record) => {
        const newExpandedRowKeys = expanded ? [record._id] : [];
        setExpandedRowKeys(newExpandedRowKeys);
    };

    // const onSelectChange = (newSelectedRowKeys, selectedRows) => {
    //     setSelectedRows(newSelectedRowKeys);
    //     setSelectedData(selectedRows);
    // };

    // const rowSelection = {
    //     preserveSelectedRowKeys: false,
    //     selectedRowKeys: selectedRows,
    //     onChange: onSelectChange,
    //     getCheckboxProps: (record) => ({
    //         disabled: record.is_generate === 1,
    //         className: record.is_generate === 1 ? 'row-generate' : '',
    //     }),
    // };

    const handleReprintCheckSheet = async (no_invoice) => {
        setLoading(true);
        await Axios.post(`${url}/warehouse-checksheets/reprint-checksheet`, {
          no_invoice: no_invoice,
        }, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('token')}`
          },
          responseType: 'blob',
        }).then(({ data }) => {
            setKeyword('');
            const file = new Blob([data], { type: 'application/pdf' });
            const fileURL = URL.createObjectURL(file);
            window.open(fileURL);
        }).catch(err => {
            if (err.response) {
                message.error(err.response.data.message)
                if (err.response.status === 422) {
                    message.error(err.response.data.errors)
                }
                if (err.response.status === 401) {
                    sessionStorage.removeItem('token')
                }
            } else {
                message.error('Backend not yet Started')
            }
        }).finally(() => {
            setLoading(false);
            getData();
        })
    }

    const handleDelete = (record) => {

        console.log("handle delete", record)
        Modal.confirm({
            title: 'Are you sure?',
            content: (
              <div>
                <p>Deleted data cannot be restored</p>
              </div>
            ),
            okText: 'Delete',
            showCancel: true,
            onCancel: () => {
                return
            },
            cancelText: 'Cancel',
            confirmLoading: loading,
            onOk: async () => {
                confirmDelete(record)
            }
        })
    }

    const confirmDelete = async (record) => {
        console.log("confirm delete", record)
        setLoading(true)
        await Axios.post(`${url}/invoice-delete`, {
            no_invoice: record.number,
            // po_number: record.po_number,
        }, {
        headers: {
            Authorization: `Bearer ${sessionStorage.getItem('token')}`
        },
        }).then(res => {
            message.success(res.data.message)
            getData()
        }).catch(err => {
            if (err.response) {  
                message.error(err.response.data.message)
                if (err.response.status === 401) {
                    sessionStorage.removeItem('token')
                }
            } else {
                message.error('Backend not yet Started')
            }
        }).finally(() => {
            setLoading(false)
            getData();
        })
    }

    const formattedNumber = (value) => {
        return value.toLocaleString('id-ID')
    };    

    const permissions = sessionStorage.getItem('permissions')
    ? JSON.parse(sessionStorage.getItem('permissions')).find(permission => 
        permission !== null && permission.name === 'Transaction')
    : [];

    const check_permission = (permission_button, name) => {
        let permission = permission_button
        ? permission_button.find(permission_upload_invoice => 
            permission_upload_invoice !== null && permission_upload_invoice.name 
            == name
            && permission_upload_invoice.allow == "true")
        : false;

        return permission;
    }

    let permission_upload_invoice = check_permission(buttonAccess, "Invoice Upload & Download Template");
    let permission_delete_invoice = check_permission(buttonAccess, "Invoice Delete");

    if(permission_upload_invoice && permission_upload_invoice.allow === "true"){
        permission_upload_invoice = true;
    }else {
        permission_upload_invoice = false;
    }

    if(permission_delete_invoice && permission_delete_invoice.allow === "true"){
        permission_delete_invoice = true;
    }else {
        permission_delete_invoice = false;
    }
    
    
    if (!sessionStorage.getItem('token')) {
        return <Redirect to="/login" />
    }

    const columns = [
        {
            title: 'Upload Date',
            dataIndex: 'created_at',
            sorter: true,
            render: (text, record, index) => {
                return moment(record.created_at).format('LL')
            }
        },
        {
            title: 'Invoice',
            dataIndex: 'number',
            sorter: true
        },
        // {
        //     title: 'PO Number',
        //     dataIndex: 'po_number',
        //     sorter: true
        // },
        // {
        //     title: 'Part Number',
        //     dataIndex: 'part_number',
        //     sorter: true
        // },
        // {
        //     title: 'Unit Price',
        //     dataIndex: 'unit_price',
        //     sorter: true
        // },
        // {
        //     title: 'SNP',
        //     dataIndex: 'snp',
        //     sorter: true
        // },
        {
            title: 'Total Packages',
            dataIndex: 'total_packages',
            sorter: true,
            render: (text, record, index) => {
                return formattedNumber(record.total_packages)
            }
        },
        {
            title : 'Total Qty',
            dataIndex : 'total_quantity',
            sorter: true,
            render: (text, record, index) => {
                return formattedNumber(record.total_quantity)
            }
        },
        {
            title : 'Source',
            dataIndex : 'sourcing',
            sorter: true
        },
        {
            title : 'Uom',
            dataIndex : 'uom',
            sorter : true
        },
        {
            title : 'Status Check',
            // dataIndex : 'status_check',
            sorter : true,
            render: (text, record, index) => {
                return (record.is_printed == 1) ? 'OK' : ''
            }
        },
        {
            title : 'Action',
            align : 'center',
            render: (text,record,index) => {
                return (
                  <Fragment>
                    <Dropdown className="pointer" overlay={
                        <Menu>
                            <Menu.Item key="1" onClick={() => handleReprintCheckSheet(record.number)}  disabled={record.is_printed !== 1}>
                              Reprint CheckSheet
                            </Menu.Item>
                            {/* {permission_delete_invoice && (
                            )} */}
                            {/* <Menu.Item key="4" onClick={() => handleDelete(record) } disabled={record.is_printed === 1}>
                                <span>Delete Invoice</span>
                            </Menu.Item> */}
                        </Menu>
                    } trigger={['click']}>
                        <span className="pointer" onClick={e => e.preventDefault()}>
                            <MoreOutlined />
                        </span>
                    </Dropdown>
                  </Fragment>
                );
            }
        },
        // {
        //     title: 'Quantity',
        //     dataIndex: 'quantity_formatted',
        //     sorter: true
        // },
        // {
        //     title: 'Amount',
        //     dataIndex: 'amount_formatted',
        //     sorter: true
        // },
    ]

    if (!sessionStorage.getItem('token')) {
        return <Redirect to="/login" />
    }

    return (
        <Fragment>
            <Row>
                <Col xs={24}>
                    <Card>
                        <Title level={4}>Upload Invoice</Title>
                            <Row type="flex" justify="left">
                                <Breadcrumb>
                                    <Breadcrumb.Item>Upload Invoice</Breadcrumb.Item>
                                </Breadcrumb>
                            </Row>
                    </Card>
                </Col>
            </Row>

            <Content className="content">
                <Row>
                    <Col xs={24}>
                        <Card className="body-data">
                            <Row type="flex" gutter={[10, 20]} justify="space-around">
                                <Col xs={24} md={12} lg={18}>
                                    <Space>
                                        {permission_upload_invoice && (
                                            <>
                                            <Link to="/invoice/upload">
                                                <Button type="primary"><UploadOutlined />Upload</Button>
                                            </Link>
                                            <Button type="primary" onClick={downloadTemplate}><CaretDownOutlined />Download Template</Button>
                                            </>
                                        )}
                                        <Button type="primary" onClick={getData}><ReloadOutlined />Refresh</Button>
                                    </Space>
                                    {/* <Upload {...uploadProps}>
                                        <Button>
                                            Upload Invoice <UploadOutlined />
                                        </Button>
                                    </Upload> */}
                                </Col>
                                <Col xs={24} md={12} lg={6} className="align-right">
                                    <Search
                                        placeholder="Search by Invoice / PO / Part"
                                        onSearch={(value) => handleSearch(value)}
                                    />
                                </Col>
                                <Col xs={24}>
                                    <Table
                                    columns={columns}
                                    dataSource={invoices.data}
                                    rowKey={(data) => data._id}
                                    pagination={pagination}
                                    loading={loading}
                                    onChange={handleTableChange}
                                    scroll={{ x: true }}
                                    // rowSelection={rowSelection}
                                    expandable={{
                                        expandedRowKeys: expandedRowKeys,
                                        onExpand: handleExpand,
                                        expandedRowRender: (record) => (
                                            <DetailInvoice
                                                key={`detail-${record._id}`}
                                                id={record._id}
                                                rows={expandedRowKeys}
                                                no_invoice={record.number}
                                                po_number={record.po_number}
                                            />
                                        ),
                                    }}
                                    />   
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                </Row>
            </Content>
        </Fragment>
    )
}

export default Invoice
